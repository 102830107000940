import React from 'react';
import { Input, Icon } from 'semantic-ui-react'
import { OrderContext } from '../Providers/OpenOrders';

export class CustomerSearch extends React.Component {
    static contextType = OrderContext;

    constructor(props) {
        super(props);

        this.state = {
            customers: [],
            isLoading: false,
            searchPhrase: undefined,
        };
    }

    componentDidMount() {
        this._fetchCustomers();
    }

    _fetchCustomers = () => {
        this.setState({
            isLoading: true,
        });

        const customers = this.context.customers;
        this.setState({
            customers,
            isLoading: false,
        });

        // CustomersController.fetchAll().then((customers) => {
        //     this.setState({
        //         customers,
        //         isLoading: false,
        //     });
        // }).catch((error) => {
        //     console.log(error);
        // });
    }

    _filteredCustomers = (customer, s) => {
        if (!customer) {
            return false;
        }

        return (customer.name && customer.name.toLowerCase().includes(s.toLowerCase()))
            || (customer.phone && customer.phone.includes(s))
            || (customer.id && customer.id.includes(s.toLowerCase()))
            || (customer.address && customer.address.toLowerCase().includes(s.toLowerCase()))
    }

    _handleSearchChanged = (e) => {
        const { value: searchPhrase } = e.target;
        this.setState({
            searchPhrase,
        });
    }

    _handleCustomerClicked = (customer) => {
        if (this.props.onCustomerClicked) {
            this.props.onCustomerClicked(customer);
        }
    }

    _renderResults = (searchPhrase) => {
        const { customers } = this.state;

        if (!searchPhrase) {
            return null;
        }

        return customers
            .filter((c) => this._filteredCustomers(c, searchPhrase))
            .map((result, idx) => (
                <div
                    key={result.id}
                    className="list-result-row"
                    tabIndex={idx}
                    onClick={() => this._handleCustomerClicked(result)}
                >
                    <div className="list-result-content">
                        <h4>{result.name}</h4>
                        <span>{result.memberClass}</span>
                    </div>
                    <div className="list-result-widget">
                        <h5>Select</h5> &nbsp;
                        <Icon name="angle right" />
                    </div>
                </div>
            ));
    }

    render() {        
        const { searchPhrase, isLoading } = this.state;

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h3>Customer Search</h3>
                { isLoading && <em>Loading customers...</em> }

                <Input
                    style={{ width: '100%' }}
                    icon='search'
                    iconPosition='left'
                    placeholder="Seach Name or Membership Number"
                    onChange={this._handleSearchChanged}
                />

                { this._renderResults(searchPhrase) }
            </div>
        );
    }
}