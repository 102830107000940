import React from 'react';
import ReactDOM from 'react-dom';

import { Router } from './Router';

import 'semantic-ui-css/semantic.min.css'
import './index.css';

ReactDOM.render(
    <Router />,
    document.getElementById('root')
);
