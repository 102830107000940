import React from 'react';
import { withRouter } from 'react-router-dom';

import { Navbar } from '../Navbar';
import { OrderContext } from '../Providers/OpenOrders';
import { Button } from 'semantic-ui-react';
import { ItemsController, CustomersController, InvoiceController } from '../../apiProxy';

class SettingsClass extends React.Component {
    static contextType = OrderContext;

    constructor(props) {
        super(props)
        this.state = {
            isCaching: false,
            currentCachingObject: undefined,
            lastStatus: undefined,
        };
    }

    _handleSyncCustomersClicked = () => {
        this.setState({
            isCaching: true,
            currentCachingObject: 'Customers',
            lastStatus: undefined,
        });
        
        CustomersController.fetchAll().then((c) => {
            this.context.setCustomers(c);
            this.setState({
                isCaching: false,
                currentCachingObject: undefined,
                lastStatus: `Synced ${c.length} customers`,
            });
        }).catch((_) => {
            this.setState({
                isCaching: false,
                currentCachingObject: undefined,
                lastStatus: `Could not sync customers. But we kept the old ones.`,
            });
        });
    }

    _handleSyncProductsClicked = () => {
        this.setState({
            isCaching: true,
            currentCachingObject: 'Products',
            lastStatus: undefined,
        });

        ItemsController.list().then((i) => {
            this.context.setProducts(i);
            this.setState({
                isCaching: false,
                currentCachingObject: undefined,
                lastStatus: `Synced ${i.length} products and services`,
            })
        }).catch((_) => {
            this.setState({
                isCaching: false,
                currentCachingObject: undefined,
                lastStatus: `Could not sync products. But we kept the old ones.`,
            });
        });
        
    }

    _handleSyncOrdersClicked = () => {
        const readyForInvoicingOrders = this.context.orders.filter((o) => o.isReadyForInvoicing);
          
        const anAsyncFunction = async (item) => {
            const invoice = await InvoiceController.add(item);
            this.context.removeOrder(item);            
        }
          
        const sendData = async () => {
            return Promise.all(readyForInvoicingOrders.map(item => anAsyncFunction(item)))
        }

        this.setState({
            isCaching: true,
            currentCachingObject: 'Completed Orders',
            lastStatus: undefined,
        });

        sendData().then((results) => {
            console.log('results', results);

            this.setState({
                isCaching: false,
                currentCachingObject: undefined,
                lastStatus: `Sent ${results.length} order${results.length === 1 ? '' : 's'} to the server.`
            });
        }).catch((error) => {
            this.setState({
                isCaching: false,
                currentCachingObject: undefined,
                lastStatus: `Failed to send orders. But we kept them on the device.`,
            });
        });
    }

    render() {
        const { getUser, orders, customers, products } = this.context;
        const openOrders = orders.filter((o) => !o.isReadyForInvoicing);
        const readyOrders = orders.filter((o) => o.isReadyForInvoicing);

        const { isCaching, currentCachingObject, lastStatus, } = this.state;

        const currentUser = getUser();

        return (
            <>
                <Navbar />
                <div className="vertical-center full-height-container-with-nav" style={{ alignItems: 'center' }}>
                    <h1>
                        Settings
                    </h1>

                    <h2>
                        You have { openOrders ? openOrders.length : 'no'  } open bill{openOrders.length === 1 ? '' : 's'},<br/>
                        { readyOrders ? readyOrders.length : 'no' } bill{readyOrders.length === 1 ? '' : 's'} ready for invoicing,<br/>
                        { customers ? customers.length : 'no' } customers cached,<br/>
                        { products ? products.length : 'no' } products cached.<br/>
                    </h2>
                    
                    <div style={{ padding: '16px 0'}}>
                        <div>
                            { currentCachingObject && <h3>Caching {currentCachingObject}...</h3> }
                        </div>
                        <div>
                            { lastStatus && <h3>{lastStatus}</h3>}
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Button
                            primary={true}
                            size="huge"
                            disabled={isCaching || readyOrders.length === 0}
                            onClick={this._handleSyncOrdersClicked}
                        >
                            Send Orders for Invoicing
                        </Button>

                        <Button
                            primary={true}
                            size="huge"
                            disabled={isCaching}
                            loading={isCaching && currentCachingObject === 'Customers'}
                            onClick={this._handleSyncCustomersClicked}
                        >
                            Re-sync Customers
                        </Button>

                        <Button
                            primary={true}
                            size="huge"
                            disabled={isCaching}
                            loading={isCaching && currentCachingObject === 'Products'}
                            onClick={this._handleSyncProductsClicked}
                        >
                            Re-sync Products
                        </Button>

                    </div>
                </div>
            </>
        );
    }
}

export const SettingsComponent = withRouter(SettingsClass);
