import React from 'react';
import {withRouter} from 'react-router-dom';
import { Navbar } from '../Navbar';
import { InvoiceController } from '../../apiProxy';
import { OrderContext } from '../Providers/OpenOrders';
import { Grid, GridColumn, Button } from 'semantic-ui-react';
import { toDollarString } from  '../../utils/utils';

class InvoicesClass extends React.Component {
    static contextType = OrderContext;
    constructor(props, state) {
        super(props, state);

        this.state = {
            invoices: [],
            invoiceNumber: undefined,
        };
    }

    componentDidMount() {
        this._fetchItems();
        this._getInvoiceNumber();
    }

    _getInvoiceNumber = () => {
        try {
            const { match: { params: { invoiceNumber } } } = this.props;
            this.setState({
                invoiceNumber,
            });
        } catch {
            //  nothing
        }
    }

    _fetchItems = () => {
        InvoiceController.list().then((invoices) => {
            console.log('invoices', invoices);
            this.setState({
                invoices,
            });
        });
    }
    
    _renderInvoice = (invoiceNumber) => {
        const invoice = this.state.invoices.find((i) => i.number === invoiceNumber);
        if (!invoice) {
            return <>Cannot find invoice</>
        }

        return (
            <>
                <h2>{ invoice.invoiceNumber }</h2>
                <div>To: {invoice.customer.name}</div>
                <div>Amount: {toDollarString(invoice.value)} </div>
                <div>Rest of invoice to be determined</div>
            </>
        )
    }

    _goToInvoice(invoiceNumber) {
        const { history } = this.props;
        history.push(`/invoices/${invoiceNumber}`);
    }
    
    _renderInvoices = (invoices) => {
        return (
            <>
                <h2>Select an invoice: </h2>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
                {
                    invoices.map((i) => (
                        <div key={i.number}>
                            <Button
                                onClick={() => this._goToInvoice(i.number)}
                            >
                                {i.number}
                            </Button>
                        </div>
                    ))}
                </div>
            </>
        )
    }

    render() {
        const { invoices, invoiceNumber } = this.state;

        return (
            <>
                <Navbar />
                <div className="vertical-center full-height-container-with-nav" style={{ alignItems: 'center' }}>
                    <h1>
                        Invoices
                    </h1>
                    { 
                        invoiceNumber
                        ? this._renderInvoice(invoiceNumber)
                        : this._renderInvoices(invoices)
                    }
                </div>
            </>
        );
    }
}

export const InvoicesComponent = withRouter(InvoicesClass);