import React from 'react';
import { withRouter } from 'react-router-dom';

import { Icon, Grid } from 'semantic-ui-react'

import { Navbar } from '../Navbar';

class CustomerDetailsComponent extends React.Component {
    constructor(props) {
        super(props);
        console.log('props', props);

        const { match: { params: { memberNumber } } } = this.props;
        this.memberNumber = memberNumber;

        this.state = {
            customer: undefined,
        };

        this.INVOICES = [
            {
                id: 1,
                memberNumber: '123456789',
                number: 'INV-0001',
                value: 193.38,
            },
            {
                id: 2,
                memberNumber: '987654321',
                number: 'INV-0002',
                value: 193.38,
            },
            {
                id: 3,
                memberNumber: '987654321',
                number: 'INV-0003',
                value: 193.38,
            },
            {
                id: 4,
                memberNumber: '456789123',
                number: 'INV-0004',
                value: 193.38,
            },
            {
                id: 5,
                memberNumber: '456789123',
                number: 'INV-0005',
                value: 193.38,
            },
            {
                id: 6,
                memberNumber: '456789123',
                number: 'INV-0006',
                value: 193.38,
            },
            {
                id: 7,
                memberNumber: '123789456',
                number: 'INV-0007',
                value: 193.38,
            },
            {
                id: 8,
                memberNumber: '123789456',
                number: 'INV-0008',
                value: 193.38,
            },
            {
                id: 9,
                memberNumber: '123789456',
                number: 'INV-0009',
                value: 193.38,
            },
            {
                id: 10,
                memberNumber: '123789456',
                number: 'INV-0010',
                value: 193.38,
            },
        ];

        this.CUSTOMERS = [
            {
                id: 1,
                firstName: 'David',
                lastName: 'James',
                memberNumber: '123456789',
                address: {
                    street: '123 May Street',
                    city: 'Regina Beach',
                    prov: 'SK',
                },
                billing: {
                    type: 'VISA',
                    ending: '1234',
                }
            },
            {
                id: 2,
                firstName: 'Bessie',
                lastName: 'James',
                memberNumber: '987654321',
                address: {
                    street: '123 May Street',
                    city: 'Regina Beach',
                    prov: 'SK',
                },
            },
            {
                id: 3,
                firstName: 'Andrey',
                lastName: 'James',
                memberNumber: '456789123',
                address: {
                    street: '123 May Street',
                    city: 'Regina Beach',
                    prov: 'SK',
                },
                billing: {
                    type: 'VISA',
                    ending: '1234',
                }
            },
            {
                id: 4,
                firstName: 'Valentin',
                lastName: 'Salmon',
                memberNumber: '123789456',
                address: {
                    street: '123 May Street',
                    city: 'Regina Beach',
                    prov: 'SK',
                },
            },
            {
                id: 5,
                firstName: 'David',
                lastName: 'James',
                memberNumber: '456123789',
                address: {
                    street: '123 May Street',
                    city: 'Regina Beach',
                    prov: 'SK',
                },
                billing: {
                    type: 'VISA',
                    ending: '1234',
                }
            }
        ]
    }

    componentWillMount() {
        if (this.memberNumber) {
            this.setState({
                customer: this.CUSTOMERS.find((x) => x.memberNumber === this.memberNumber),
            });
        }
    }

    _handleInvoiceClicked = (invoiceId) => {
        const { history } = this.props;
        const invoice = this.INVOICES.find((x) => x.id === invoiceId);

        history.push(`/invoices/${invoice.number}`);
    }

    _renderCustomerDetails = (customer) => {
        if (!customer) {
            return null;
        }

        return (
            <>
                <div>{ customer.firstName } { customer.lastName }</div>
                <div>{ customer.address.street}</div>
                <div>{ customer.address.city}, {customer.address.prov}</div>

                <h4>Billing</h4>
                {
                    customer.billing
                    ? <div>{ customer.billing && customer.billing.type} ending in {customer.billing.ending}</div>
                    : <div><em>No billing details on file.</em></div>
                }
            </>
        );
    }

    _renderCustomerInvoices = (customer) => {
        if (!customer) {
            return null;
        }

        const memberInvoices = this.INVOICES.filter((x) => x.memberNumber === customer.memberNumber);

        if (!memberInvoices || !memberInvoices.length) {
            return (
                <div>
                    No open invoices for customer.
                </div>
            );
        }

        return memberInvoices.map((invoice, idx) => (
            <div
                key={invoice.id}
                className="list-result-row"
                tabIndex={idx}
                onClick={() => this._handleInvoiceClicked(invoice.id)}
            >
                <div className="list-result-content">
                    <h4>{invoice.number}</h4>
                    <span>{invoice.value}</span>
                </div>
                <div className="list-result-widget">
                    <h5>Select</h5> &nbsp;
                    <Icon name="angle right" />
                </div>
            </div>
        ));
    }

    render() {
        const { customer } = this.state;

        return (
            <>
                <Navbar />
                <div
                    className="vertical-center full-height-container-with-nav content"
                    style={{ justifyContent: 'flex-start' }}
                >
                    <Grid style={{width: '100%'}}>
                        <Grid.Row>
                            <Grid.Column width={8} style={{height: '100%' }}>
                                <h3>Customer Details</h3>
                                { this._renderCustomerDetails(customer) }
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <h3>Invoices</h3>
                                { this._renderCustomerInvoices(customer) }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </div>
            </>
        );
    }
}

export const CustomerDetails = withRouter(CustomerDetailsComponent);