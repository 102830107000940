import React from 'react';

export const OrderContext = React.createContext();

const USER_KEY = 'com.rbyc.app.CurrentUser';
const ORDERS_KEY = 'com.rbyc.app.Orders';
const PRODUCTS_KEY = 'com.rbyc.app.Products';
const CUSTOMERS_KEY = 'com.rbyc.app.Customers';

export class OrderContextProvider extends React.Component {
    constructor(props, state) {
        super(props, state);

        const cachedOrders = JSON.parse(localStorage.getItem(ORDERS_KEY)) || [];
        const cachedProducts = JSON.parse(localStorage.getItem(PRODUCTS_KEY)) || [];
        const cachedCustomers = JSON.parse(localStorage.getItem(CUSTOMERS_KEY)) || [];

        this.state = {
            currentUser: undefined,
            currentOrder: undefined,

            orders: cachedOrders, // TODO: get open orders
            products: cachedProducts,
            customers: cachedCustomers,
        };
    }

    _persistProducts = (products) => {
        localStorage.setItem(PRODUCTS_KEY, JSON.stringify(products));
        this.setState({
            products,
        });
    }

    _persistCustomers = (customers) => {
        localStorage.setItem(CUSTOMERS_KEY, JSON.stringify(customers));
        this.setState({
            customers,
        });
    }

    _hydrateProducts = () => {
        const products = JSON.parse(localStorage.getItem(PRODUCTS_KEY)) || [];
        this.setState({
            products,
        });
    }

    _hydrateCustomers = () => {
        const customers = JSON.parse(localStorage.getItem(CUSTOMERS_KEY)) || [];
        this.setState({
            customers,
        });
    }

    _persistOrder = (updatedOrder) => {
        const savedOrders = JSON.parse(localStorage.getItem(ORDERS_KEY)) || [];

        console.log('ORDERS', savedOrders);

        const orderIndex = savedOrders.findIndex((o) => o.id === updatedOrder.id);
        if (orderIndex < 0) {
            savedOrders.push(updatedOrder);
        } else {
            savedOrders[orderIndex] = updatedOrder;
        }

        localStorage.setItem(ORDERS_KEY, JSON.stringify(savedOrders));
    }

    _addOrder = (newOrder) => {
        const { orders } = this.state;

        orders.push(newOrder);

        this._persistOrder(newOrder);
        
        this.setState({
            orders,
            currentOrder: orders[orders.length - 1],
        });
    }

    _removeOrder = (orderToDelete) => {
        const newOrders = this.state.orders.filter((order) => order.id !== orderToDelete.id);
        localStorage.setItem(ORDERS_KEY, JSON.stringify(newOrders));

        this.setState({
            orders: newOrders,
        });
    }

    _setCurrentOrder = (orderId) => {
        if (!orderId) {
            this.setState({
                currentOrder: undefined,
            });
            return;
        }
        
        const { orders } = this.state;
        
        const currentOrder = orders.find((order) => order.id === orderId);
        this.setState({
            currentOrder,
        });
    }

    _updateCurrentOrder = (delta) => {
        const { currentOrder, orders } = this.state;

        const updatedOrder = {
            ...currentOrder,
            ...delta,
        };

        const updatedOrders = [
            ...orders.filter((o) => o.id !== updatedOrder.id),
            updatedOrder,
        ];

        this._persistOrder(updatedOrder);

        this.setState({
            currentOrder: updatedOrder,
            orders: updatedOrders,
        });
    }

    _getCurrentOrder = () => {
        return this.state.currentOrder;
    }

    _setUser = (newUser) => {
        localStorage.setItem(USER_KEY, JSON.stringify(newUser));

        this.setState({
            currentUser: newUser,
        });
    }

    _removeUser = () => {
        this.setState({
            currentUser: undefined,
        }, () => {
            localStorage.removeItem(USER_KEY);
        });
    }

    _getUser = () => {
        if (!this.state.currentUser) {
            const currentUserSerialized = localStorage.getItem(USER_KEY);
            const currentUser = JSON.parse(currentUserSerialized);
            if (currentUser) {
                this.setState({
                    currentUser,
                });
            }
        }

        return this.state.currentUser;
    }

    _updateItemInCurrentOrder = (item) => {
        const { currentOrder: order } = this.state;

        console.log('order items', order.items);
        console.log('update item', item);

        if (item.quantity === 0) {
            order.items = order.items.filter((i) => i.itemId !== item.itemId);
        } else {
            const idx = order.items.findIndex((i) => i.itemId === item.itemId);
            const thisItem = order.items[idx];

            order.items[idx] = {
                ...thisItem,
                quantity: item.quantity,
                lineTotal: thisItem.unitPrice * item.quantity,
            }
        }

        // recalculate bill total
        let value = 0;
        for(const item of order.items) {
            value += item.lineTotal;
        }
        order.value = value;

        this._persistOrder(order);

        this.setState({
            currentOrder: order,
        });
    }

    _addItemToCurrentOrder = (item) => {
        const { currentOrder: order } = this.state;
        if (!order.items) {
            order.items = [];
        }

        const currentIndex = order.items.findIndex((i) => i.itemId === item.id);
        if (currentIndex < 0) {
            order.items.push({
                itemId: item.id,
                name: item.name,
                description: item.description,
                quantity: 1,
                unitPrice: item.memberPrice,
                lineTotal: item.memberPrice,
            });
        } else {
            order.items[currentIndex].quantity += 1;
            order.items[currentIndex].lineTotal = 
                order.items[currentIndex].quantity * order.items[currentIndex].unitPrice;
        }
        
        // recalculate bill total
        let value = 0;
        for(const item of order.items) {
            value += item.lineTotal;
        }
        order.value = value;

        this._persistOrder(order);

        this.setState({
            currentOrder: order,
        });
    }

    render() {
        return (
            <OrderContext.Provider
                value={{
                    orders: this.state.orders,
                    currentUser: this.state.currentUser,
                    
                    addOrder: this._addOrder,
                    removeOrder: this._removeOrder,
                    
                    setCurrentOrder: this._setCurrentOrder,
                    updateCurrentOrder: this._updateCurrentOrder,
                    getCurrentOrder: this._getCurrentOrder,
                    updateItemInCurrentOrder: this._updateItemInCurrentOrder,
                    addItemToCurrentOrder: this._addItemToCurrentOrder,

                    getUser: this._getUser,
                    setUser: this._setUser,
                    removeUser: this._removeUser,

                    products: this.state.products,
                    customers: this.state.customers,

                    setProducts: this._persistProducts,
                    setCustomers: this._persistCustomers,
                }}
            >
                { this.props.children }
            </OrderContext.Provider>
        );
    }
}
