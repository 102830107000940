// TODO: convert to ts
const API_ROOT = '/api';

const ENDPOINTS = {
    Users: 'users',
    Customers: 'customers',
    Items: 'services',
    Invoices: 'invoices',
};

const getHeaders = () => {
    return {
        'user-agent': 'rbyc-web',
        'content-type': 'application/json',
    }
}

export const InvoiceController = {
    list: () => {
        const URL = `${API_ROOT}/${ENDPOINTS.Invoices}`;
        const options = {
            method: 'get',
            headers: getHeaders(),
        };

        return fetch(URL, options)
        .then((apiResult) => apiResult.json())
        .then((parsedApiResult) => {
            const { err, invoices } = parsedApiResult;
            
            if (err) {
                throw new Error(err);
            }

            return parsedApiResult.invoices;
        }).catch((err) => {
            throw new Error(err);
        });

    },
    add: (order) => {
        const URL = `${API_ROOT}/${ENDPOINTS.Invoices}`;
        const options = {
            method: 'post',
            headers: getHeaders(),
            body: JSON.stringify(order),
        };
        
        return fetch(URL, options)
            .then((apiResult) => apiResult.json())
            .then((parsedApiResult) => {
                const { err } = parsedApiResult;
                
                if (err) {
                    throw new Error(err);
                }

                return parsedApiResult;
            }).catch((err) => {
                throw new Error(err);
            });
    }
}

export const UsersController = {
    login: (email, password) => {
        const URL = `${API_ROOT}/${ENDPOINTS.Users}/signin`;
        const options = {
            method: 'post',
            headers: getHeaders(),
            body: JSON.stringify({
                email,
                password,
            }),
        };
        
        return fetch(URL, options)
            .then((apiResult) => apiResult.json())
            .then((parsedApiResult) => {
                const { err, user } = parsedApiResult;
                
                if (err) {
                    throw new Error(err);
                }

                if (user) {
                    return {
                        ...user,
                    };
                }
            });
    }
}

export const CustomersController = {
    fetchAll: () => {
        const URL = `${API_ROOT}/${ENDPOINTS.Customers}`;
        const options = {
            method: 'get',
            headers: getHeaders(),
        };
        
        return fetch(URL, options)
            .then((apiResult) => apiResult.json())
            .then((parsedApiResult) => {
                const { err, customers } = parsedApiResult;
                
                if (err) {
                    throw new Error(err);
                }

                if (customers) {
                    return customers
                }
            });
    }
}

export const ItemsController = {
    list: () => {
        const URL = `${API_ROOT}/${ENDPOINTS.Items}`;
        const options = {
            method: 'get',
            headers: getHeaders(),
        };
        
        return fetch(URL, options)
            .then((apiResult) => apiResult.json())
            .then((parsedApiResult) => {
                const { err, items } = parsedApiResult;
                
                if (err) {
                    throw new Error(err);
                }

                return items || [];
            }).catch((err) => {
                throw new Error(err);
            });
    }
}