import * as React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

// User Routes
import { LoginContainer } from './containers/Login';
import { DashboardContainer } from './containers/Dashboard';

import { CustomersContainer } from './containers/Customers';
import { CustomerDetailsContainer } from './containers/CustomerDetails';
import { OrdersContainer } from './containers/Orders';
import { OrderContextProvider } from './components/Providers/OpenOrders';
import { SettingsComponent } from './components/Settings';
import { InvoicesComponent } from './components/Invoices';

// Admin Routes
// import { AdminOrder } from './containers/AdminOrders';
// import { AdminProducts } from './containers/AdminProducts';

// Context Providers


export class Router extends React.Component {
    render() {
        return (
            <OrderContextProvider>
                <BrowserRouter>
                    <Route path="/" exact={true} component={LoginContainer} />

                    <Route path="/dashboard" exact={true} component={DashboardContainer} />
                    <Route path="/customers" exact={true} component={CustomersContainer} />
                    <Route path="/customers/:memberNumber" exact={true} component={CustomerDetailsContainer} />

                    <Route path="/orders/:invoiceNumber" exact={false} component={OrdersContainer} />
                    <Route path="/orders" exact={true} component={OrdersContainer} />

                    <Route path="/settings" exact={true} component={SettingsComponent} />
                    
                    <Route path="/invoices/:invoiceNumber" exact={false} component={InvoicesComponent} />
                    <Route path="/invoices" exact={true} component={InvoicesComponent} />
                </BrowserRouter>
            </OrderContextProvider>
        );
    }
}