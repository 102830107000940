import React from 'react';
import { withRouter } from 'react-router-dom';

import { Grid, Button, Checkbox, Form, Input } from 'semantic-ui-react';
import { UsersController, CustomersController, ItemsController } from '../../apiProxy';

import { OrderContext } from '../Providers/OpenOrders';

class LoginClass extends React.Component {
    static contextType = OrderContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            email: '',
            password: '',
            rememberMe: false,
            isCaching: false,
            currentCachingObject: 'Nothing',
        };

        this._checkForAuth();
    }

    _checkForAuth = () => {
        const serializedUser = localStorage.getItem('com.rbyc.app.CurrentUser');
        if (!serializedUser) {
            return;
        }

        const user = JSON.parse(serializedUser);
        if (user && user.id) {
            this._cacheObjects(this._routeToDashboard);
        }
    }

    _cacheObjects = async (callback) => {
        this.setState({
            isCaching: true,
            currentCachingObject: 'Customers',
        });

        const customers = await CustomersController.fetchAll();
        this.context.setCustomers(customers);

        this.setState({
            currentCachingObject: 'Products'
        });

        const products = await ItemsController.list();
        this.context.setProducts(products);

        callback();
    }

    _routeToDashboard = () => {
        const { history } = this.props;
        history.push('/dashboard');
    }

    _handleLoginClicked = () => {
        this.setState({
            isLoading: true,
            message: undefined,
        });

        UsersController.login(
            this.state.email,
            this.state.password,
        ).then((result) => {
            
            if (result 
                && result.role
                && (result.role === 'admin' || result.role === 'user')
            ) {
                const { setUser } = this.context;
                if (setUser) {
                    setUser(result);
                }

                this.setState({
                    isLoading: false,
                }, () => {
                    this._cacheObjects(this._routeToDashboard);
                    return;
                });
            }
        }).catch((error) => {
            this.setState({
                message: error.message,
            });
        });
    }

    _handlePasswordChanged = (e) => {
        this.setState({
            password: e.target.value,
        })
    }

    _handleEmailChanged = (e) => {
        this.setState({
            email: e.target.value,
        });
    }

    _handleRememberMeClicked = (e) => {
    }

    _renderLoginForm = () => {
        return (
            <div className="vertical-center">
                <img src="/images/logo.png" alt="Regina Beach Yacht Club"/>

                <h2>Welcome. Please login to your account</h2>
                
                <div>
                    <p>{ this.state.message }</p>
                </div>
                
                <Form>
                    <Form.Field
                        control={Input}
                        label="Username"
                        placeholder="Username"
                        type="email"
                        onChange={this._handleEmailChanged}
                    />

                    <Form.Field
                        control={Input}
                        label="Password"
                        placeholder="Password"
                        type="password"
                        onChange={this._handlePasswordChanged}
                    />

                    <Form.Field
                        control={Checkbox}
                        label={{ children: 'Remember me' }}
                        onChange={this._handleRememberMeClicked}
                    />

                    <Button
                        primary={true}
                        size="huge"
                        loading={this.state.isLoading}
                        onClick={this._handleLoginClicked}
                    >
                        Login
                    </Button>
                </Form>
            </div>
        )
    }

    _renderCaching = () => {
        return (
            <div className="vertical-center">
                Caching { this.state.currentCachingObject }...
            </div>
        )
    }

    render() {
        return (
            <Grid className="no-margin">
                <Grid.Row className="full-height-container">
                    <Grid.Column
                        width={8}
                        className="login-sidebar"
                    />
                    <Grid.Column
                        width={8}
                    >
                        { 
                            this.state.isCaching
                                ? this._renderCaching()
                                : this._renderLoginForm() 
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export const LoginComponent = withRouter(LoginClass);