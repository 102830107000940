import React from 'react';

import { Navbar } from '../Navbar';
import { OrderContext } from '../Providers/OpenOrders';

export class DashboardComponent extends React.Component {
    static contextType = OrderContext;

    constructor(props) {
        super(props)
        this.state = {};
    }

    render() {
        const { getUser, orders } = this.context;
        const openOrders = orders.filter((o) => !o.isReadyForInvoicing);
        const readyOrders = orders.filter((o) => o.isReadyForInvoicing);

        const currentUser = getUser();

        return (
            <>
                <Navbar />
                <div className="vertical-center full-height-container-with-nav" style={{ alignItems: 'center' }}>
                    <h1>
                        Welcome { currentUser && currentUser.firstName }
                    </h1>

                    <h2>
                        You have { openOrders ? openOrders.length : 'no'  } open bill{readyOrders.length === 1 ? '' : 's'}, &nbsp;
                        { readyOrders ? readyOrders.length : 'no' } bill{readyOrders.length === 1 ? '' : 's'} ready for invoicing.
                    </h2>

                    <img src="/images/fishing.png" alt="Fishing" />
                </div>
            </>
        );
    }
}