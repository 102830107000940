import React from 'react';

import { withRouter } from 'react-router-dom';
import { Button, Container, Grid, List, Table, Icon, Input } from 'semantic-ui-react'

import { Navbar } from '../Navbar';

import { OrderContext } from '../Providers/OpenOrders';
import { CustomerSearch } from '../CustomerSearch';
import { toDollarString } from  '../../utils/utils';

import './orders.css';

class OrdersClass extends React.Component {
    static contextType = OrderContext;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            isEditingItemQtyId: undefined,
            invoiceNumber: '',
        };
    }

    componentWillMount() {
        this._fetchItems();
        this._getInvoiceNumber();
    }

    _getInvoiceNumber = () => {
        try {
            const { match: { params: { invoiceNumber } } } = this.props;
            this.setState({
                invoiceNumber,
            });
        } catch {
            //  nothing
        }
    }

    _getOrder = () => {
        const { invoiceNumber } = this.state;
        if (!invoiceNumber) {
            return;
        }
        
        const order = this.context.orders.find((o) => o.number === invoiceNumber);
        if  (order) {
            this.context.setCurrentOrder(order.id);
        }
    }

    _fetchItems = () => {
        const items = this.context.products;
        this.setState({
            items,
        });
    }

    _getHistory = () => {
        return this.props.history;
    }

    _handleItemAdded = (item) => {
        this.context.addItemToCurrentOrder(item);
    }

    _handleOrderDeleted = (orderToDelete) => {
        this.context.removeOrder(orderToDelete);
        this.context.setCurrentOrder(null);
    }

    _renderServices = () => {
        return (
            <Container className="shadowed padded">
                <h4>Add Service</h4>
                <List divided relaxed className="services-list">
                    { this.state.items.map((i) => (
                        <List.Content style={{ margin: '8px auto'}} key={i.id}>
                            <div className="services-content" tabIndex={0} onClick={() => this._handleItemAdded(i)}>
                                <div>
                                    <div>
                                        <List.Header as='h5'>
                                            { i.name }
                                        </List.Header>
                                    </div>
                                    <div>
                                        <List.Description as='h5'>
                                            { i.description }
                                        </List.Description>
                                    </div>
                                </div>
                                <span>
                                    <Icon circular link name='plus' /> Add
                                </span>
                            </div>
                        </List.Content>
                    ))}
                </List>
            </Container>
        )
    }

    _renderOpenBill = (order) => {
        const { items } = order;

        return (
            <Container
                className="shadowed padded bill-container"
            >
                <div>
                    <h4>Open Bill</h4>
                    <Table basic="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    <h4>Name</h4>
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <h4>Quantity</h4>
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="right">
                                    <h4>Unit Price</h4>
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="right">
                                    <h4>Line Total</h4>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>                    
                        { 
                        !items || !items.length
                            ? (
                            <Table.Row>
                                <Table.Cell className="empty-cell" textAlign="center" verticalAlign="middle" colSpan={4}>
                                    <em>Add some items to the order</em>
                                    <br/>
                                    <Button
                                        onClick={() => this._handleOrderDeleted(order)}
                                    >
                                        Delete Order
                                    </Button>
                                </Table.Cell>
                            </Table.Row>)
                            : 
                            items.map((i) => (
                            <Table.Row key={i.itemId}>
                                <Table.Cell>
                                    <h4>{ i.name }</h4>
                                    { i.description }
                                </Table.Cell>

                                <Table.Cell className="quantity-cell">
                                    {
                                        this.state.isEditingItemQtyId === i.itemId
                                        ? 
                                        (<Input
                                            placeholder="Qty"
                                            type="number"
                                            style={{ width: '6em' }}
                                            value={i.quantity}
                                            onChange={(e) => {
                                                this.context.updateItemInCurrentOrder({...i, quantity: parseInt(e.currentTarget.value) })
                                            }}
                                            onBlur={() => {
                                                this.setState({ isEditingItemQtyId: undefined });
                                            }}
                                        />)
                                        : (<span
                                            style={{ display: 'block', padding: '4px 8px', margin: '4px', border: '0px solid #f00', width: '6em !important' }}
                                            onClick={() => this.setState({ isEditingItemQtyId: i.itemId })}
                                        >
                                            { i.quantity }
                                        </span>)
                                    }
                                    <Icon circular link name="trash" onClick={() => this.context.updateItemInCurrentOrder({...i, quantity: 0 })}/>
                                </Table.Cell>

                                <Table.Cell textAlign="right">
                                    { toDollarString(i.unitPrice) }
                                </Table.Cell>

                                <Table.Cell textAlign="right">
                                    <strong>{ toDollarString(i.lineTotal) }</strong>
                                </Table.Cell>
                            </Table.Row>
                        ))
                        }
                        </Table.Body>
                    </Table>
                </div>
                
                <div className="total-summary">
                    <h2>Total: { toDollarString(order.value) }</h2>
                </div>
                
            </Container>
        );
    }

    _cancelOpenBill = (orderToDelete) => {
        // TODO: confirmation dialog
        if (window.confirm('Really delete? This will remove the order entirely.')) {
            this.context.removeOrder(orderToDelete);
            this.context.setCurrentOrder(null);
        }
    }

    _saveOpenBill =  () => {
        if (window.confirm('Relly save and close? This will prep it for invoicing, and you cannot make changes.')) {
            this.context.updateCurrentOrder({ isReadyForInvoicing: true });
            this.context.setCurrentOrder(null);
        }
    }

    _renderCurrentOrder = (order) => {
        if (!order || !order.id) {
            return null;
        }

        return (
            <>
                {
                    order.customer ? (
                        <>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    { this._renderServices() }
                                </Grid.Column>
                                <Grid.Column width={10}>
                                    { this._renderOpenBill(order) }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <div className="action-buttons">
                            <Button onClick={() => this._cancelOpenBill(order)}>Cancel</Button>
                            <Button primary onClick={() => this._saveOpenBill(order)}>Save &amp; Close</Button>
                        </div>
                        </>
                    ) : (
                        <div>
                        You must select a customer:
    
                        <CustomerSearch
                            onCustomerClicked={(customer) => this.context.updateCurrentOrder({ customer })}
                        />
                    </div>
                    )
                }
            </>
        );
    }

    _handleSyncClicked = () => {
        const { history } = this.props;
        history.push('/settings');
    }
    
    _handleNewOrderClicked = () => {
        // TODO: make a new open bill
        const newOrder = {
            id: Math.floor(Math.random() * 1000),
            // TODO: maybe a UUID instead?
        };

        this.context.addOrder(newOrder);
        this.context.setCurrentOrder(newOrder.id);
        this._getHistory().push(`/orders/${newOrder.id}`);
    }

    render() {
        const { getCurrentOrder } = this.context;
        const order = getCurrentOrder();

        return (
            <>
                <Navbar />
                <h2>Services</h2>
                <Container
                    style={{ height: '50vh'}}
                >
                    {
                        (order && order.id) 
                        ? this._renderCurrentOrder(order) 
                        : 
                    (
                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <em>No order selected</em>
                            <p>Why don't you <a href="#" onClick={this._handleNewOrderClicked}>create one</a>?</p>
                            <p>Or, check your <a href="#" onClick={this._handleSyncClicked}>sync settings</a>.</p>
                        </div>
                    )
                    }
                </Container>
            </>
        );
    }
}

export const OrdersComponent = withRouter(OrdersClass);