import React from 'react';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { OrderContext } from '../Providers/OpenOrders';

class NavbarComponent extends React.Component {
    static contextType = OrderContext;
    constructor(props) {
        super(props);

        this.state = {};

    }

    _getHistory = () => {
        return this.props.history;
    }

    _handleHomeClicked = () => {
        this._getHistory().push('/dashboard');
    }

    _handleOrdersClicked = () => {
        this._getHistory().push('/orders');
    }

    _handleCustomersClicked = () => {
        this._getHistory().push('/customers');
    }

    _handleSettingsClicked = () => {
        this._getHistory().push('/settings');
    }

    _handleLogoutClicked = () => {
        localStorage.removeItem('com.rbyc.app.CurrentUser');
        this._getHistory().push('/');
    }

    _handleOpenOrderClicked = (orderId) => {
        this.context.setCurrentOrder(orderId);
        this._getHistory().push(`/orders/${orderId}`);
    }

    _handleInvoicesClicked = (orderId) => {
        this._getHistory().push(`/invoices/`);
    }

    _renderOrders = () => {
        const { orders, getCurrentOrder } = this.context;
        const currentOrder = getCurrentOrder();

        const { id: currentOrderId } = currentOrder || {};

        return orders.filter((o) => !o.isReadyForInvoicing).map((order, idx) => (
            <div
                tabIndex={idx}
                key={order.id}
                style={{ display: 'inline', padding: '0 8px', borderBottom: currentOrderId === order.id ? '2px solid #000' : 'none' }}
                onClick={() => this._handleOpenOrderClicked(order.id)}
            >
                { (order && order.customer && order.customer.name) || 'No customer' }
            </div>
        ));
    }

    _handleNewBillClicked = () => {
        // TODO: make a new open bill
        const newOrder = {
            id: Math.floor(Math.random() * 1000),
            // TODO: maybe a UUID instead?
        };

        this.context.addOrder(newOrder);
        this.context.setCurrentOrder(newOrder.id);
        this._handleOpenOrderClicked(newOrder.id);
    }

    render() {
        const { getUser } = this.context;
        let displayName;
        
        const user = getUser();
        if (user) {
            displayName = `${user.firstName} ${user.lastName ? user.lastName.charAt(0) : ''}`;
        }

        return (
            <div
                className="navBar"
            >
                <div>
                    <img src="/images/logo-sm.png" alt="RBYC" />
                    {/* context for open invoices */}
                    { this._renderOrders() }
                </div>


                <div>
                    <Button
                        primary={true}
                        style={{ paddingRight: 32, marginRight: 32 }}
                        onClick={this._handleNewBillClicked}
                    >
                        <Icon name="plus" circular={true} /> New Bill
                    </Button>

                    <Dropdown text={displayName || ''}>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={this._handleHomeClicked} icon="home" text="Home" />
                            <Dropdown.Item onClick={this._handleOrdersClicked} icon="file alternate" text="Orders" />
                            <Dropdown.Item onClick={this._handleCustomersClicked} icon="user outline" text="Customers" />
                            <Dropdown.Item onClick={this._handleSettingsClicked} icon="setting" text="Settings" />
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={this._handleInvoicesClicked} icon="money" text="Invoices" />
                            <Dropdown.Item onClick={this._handleLogoutClicked} icon="undo" text="Logout" />
                        </Dropdown.Menu>
                    </Dropdown>
                    <Icon style={{ marginLeft: 16 }} name="user" circular={true} size="large" />
                </div>
            </div>
        );
    }
}

export const Navbar = withRouter(NavbarComponent);