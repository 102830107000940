import React from 'react';
import { withRouter } from 'react-router-dom';
import { Navbar } from '../Navbar';

class CustomersComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    _handleCustomerClicked = (customerId) => {
        const { customers } = this.state;

        const customer = customers.find((x) => x.id === customerId);
        if (customer) {
            const { history } = this.props;
            history.push(`/customers/${customer.id}`);
        }
    }

    render() {
        return (
            <>
                <Navbar />
                <div
                    className="vertical-center full-height-container-with-nav content"
                    style={{ justifyContent: 'flex-start' }}
                >
                    <h1>Customers</h1>
                </div>
            </>
        );
    }
}

export const Customers = withRouter(CustomersComponent);